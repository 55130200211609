<template>
  <b-card>
    
    <loader v-if="loading"></loader>
    <ImmobileForm v-if="form.immobile.id !== ''" ref="immobile_form" @formDataChanged="formDataChanged" :formData="form.immobile" :externalFileData="externalFileData" :deliberation="true"></ImmobileForm>
    <b-row class="pt-1 mt-2 mr-1" align-h="end">
      <b-col sm="2" class="ml-3">
        <b-button style="width: 100%;" variant="primary" @click="$router.go(-1)">Voltar</b-button>
      </b-col>
      <b-col sm="2" class="ml-3">
        <b-button style="width: 100%;" variant="primary" @click="saveForm">Salvar</b-button>
      </b-col>
    </b-row>
    <ImmobileComents v-if="form.immobile.id !== ''"  :immobileId="form.immobile.id"/>
  </b-card>
</template>

<script>
import { BRow, BCol, BButton, BCard } from 'bootstrap-vue'
import toast from '@/mixins/toast'
import ImmobileForm from './ImmobileForm.vue'
import ImmobileComents from '@/views/comments/immobile/ImmobileComents'
import Loader from '../components/Loader.vue'
import ImmobileDocumentsService from '@/services/Immobile/ImmobileDocumentsService'
import ImmobilePhotosService from '@/services/Immobile/ImmobilePhotosService'

export default {

  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    ImmobileForm,
    ImmobileComents,
    Loader,
  },
  mixins: [toast],

  data() {
    return {
      address: {},
      loading: false,
      form: {
        immobile: {
          name: '',
          unitNumber: '',
          privateAreaSize: 0,
          suiteCount: 0,
          bedroomCount: 0,
          bathroomCount: 0,
          toiletCount: 0,
          floorCount: 0,
          parkingCount: 0,
          hasServiceArea: true,
          hasCloset: true,
          hasPrivatePool: true,
          hasMaidRoom: true,
          hasBarbecue: true,
          hasMachineryArea: true,
          expectedRemuneration: 0,
          essentialInformation: '',
          quotaCount: 0,
          quotaValue: 0,
          quotaDeadlineSell50: null,
          publishDate: null,
          seem: '',
          situation: 1,
          immobileTypeId: '',
          immobileAddressId: '',
          immobileRatingId: '',
          projectId: '',
          id: '',
          valueReceived: 0,
        },
        immobileAddressStreetName: null,
        immobileDeliberationStatusName: null,
        immobileRatingName: null,
        immobileTypeName: null,
        projectName: '',
      },

      externalFileData: {
        attachments: [],
        bluePrint: [],
        bluePrintAll: [],
        document: [],
      },

      immobileDocumentStatus: [],

      companyId: '7238ed0e-fb91-4c09-b1e3-08d9c3f09918',
      companyAbout: '',

      service: null,
      closet: null,
      piscina: null,
      quartoEmp: null,
      churrasqueira: null,
      maquinas: null,
      projetoObra: null,
      options: [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' },
      ],
      selectedUf: null,
      listUf: [],
      selectedType: null,
      types: [],
      selectedStatus: null,
      status: [],
      selectedRating: null,
      ratings: [],
      projetos: [
        { value: 'proj01', text: 'Nome do Projeto/Obra 01' },
        { value: 'proj02', text: 'Nome do Projeto/Obra 02' },
        { value: 'proj03', text: 'Nome do Projeto/Obra 03' },
        { value: 'proj04', text: 'Nome do Projeto/Obra 04' },
        { value: 'proj05', text: 'Nome do Projeto/Obra 05' },
      ],
    }
  },

  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },

  mounted() {
    // TODO
    const { id } = this.$route.params.company
    this.getImmobile(id ?? '')
    this.getSystemEstate()
    this.getTypeList()
    // this.getCompanyInfo(id)
    this.getDataImmobileBluePrints(id)
    this.getDataImmobilePhotos(id)
    this.getDataImmobileDocuments(id)
    this.getSystemImmobileDocumentStatus()
  },

  methods: {
    formDataChanged($event) {
      //debugger
      const ev = $event
      ev.publishDate = ev.publishDate ? ev.publishDate.split('T')[0] : null
      ev.quotaDeadlineSell50 = ev.quotaDeadlineSell50 ? ev.quotaDeadlineSell50.split('T')[0] : null
      this.form.immobile = $event
    },
    async getTypeList() {
      try {
        const p = await this.$http.get('/api/services/app/ImmobileTypes/GetAll')
        this.types = p.data.result.items.map(m => ({ value: m.immobileType.id, text: m.immobileType.name }))
      } catch (e) {
        if (!this.msgIsOpen()) this.msgError('Houve um erro ao obter a lista de tipos de imóveis')
      }
    },

    async getSystemEstate() {
      try {
        const p = await this.$http.get('/api/services/app/Estates/GetAll')
        this.listUf = p.data.result.items.map(res => ({ value: res.estate.id, text: res.estate.name }))
      } catch (e) {
        if (!this.msgIsOpen()) this.msgError('Houve um erro ao obter lista de UFs')
      }
    },

    async getImmobile(id) {
      try {
        const p = await this.$http.get(`/api/services/app/Immobiles/GetImmobileForEdit?Id=${id}`)
        const { immobile } = p.data.result
        p.data.result.immobile.publishDate = immobile.publishDate ? immobile.publishDate.split('T')[0] : null
        p.data.result.immobile.quotaDeadlineSell50 = immobile.quotaDeadlineSell50 ? immobile.quotaDeadlineSell50.split('T')[0] : null
        this.form = p.data.result
        this.companyAbout = p.data.result.aboutConstructionCompany

      } catch (e) {
        if (!this.msgIsOpen()) this.msgError('Houve um erro ao obter dados do imóvel')
      }
      this.getAddress()
    },

    async getAddress() {
      try {
        const p = await this.$http.get(`/api/services/app/ImmobileAddresses/GetImmobileAddressForEdit?id=${this.form.immobile.immobileAddressId}`)
        this.address = p.data.result.immobileAddress
      } catch (e) {
        if (!this.msgIsOpen()) this.msgError('Houve um erro ao obter os dados de endereço do imóvel')
      }
    },

    async getDataImmobileBluePrints(id) {
      try {
        const r = await this.$http.get(`/api/services/app/ImmobileBluePrints/GetAll?ImmobileIdFilter=${id}`)
        // console.log(r)
        this.externalFileData.bluePrint = r.data.result.items
      } catch (e) {
        if (!this.msgIsOpen()) this.msgError('Houve um erro ao obter os darquivos do imóvel')
      }
    },
    async getDataImmobilePhotos(id) {
      try {
        const r = await ImmobilePhotosService.getAll(id)
        this.externalFileData.attachments = r.data.result.items
      } catch (e) {
        if (!this.msgIsOpen()) this.msgError('Houve um erro ao obter os darquivos do imóvel')
      }
    },
    async getDataImmobileDocuments(id) {
      try {
        const r = (await ImmobileDocumentsService.getAll(id))
        // console.log(r)
      this.externalFileData.document = r.data.result.items.filter(d => d.immobileDocumentTypeCode === 0)
      this.externalFileData.documentosContratuais = r.data.result.items.filter(d => d.immobileDocumentTypeCode === 1)
      } catch (e) {
        if (!this.msgIsOpen()) this.msgError('Houve um erro ao obter os darquivos do imóvel')
      }
    },

    async getSystemImmobileDocumentStatus() {
      try {
        const r = await this.$http.get('/api/services/app/ImmobileDocumentStatuses/GetAll')
        // console.log(r)
        this.immobileDocumentStatus = r.data.result.items.map(res => ({
          value: res.immobileDocumentStatus.id,
          text: res.immobileDocumentStatus.name,
        }))
      } catch (e) {
        if (!this.msgIsOpen()) this.msgError('Houve um erro ao obter o status do imóvel')
      }
    },

    async saveForm() {
      const valid = await this.$refs.immobile_form.validate()
      if (valid) {
        this.register()
      } else {
        this.msgError('Preencha todos os campos')
      }
    },

    async register() {
      this.loading = true
      const { immobile_form } = this.$refs
      const { seem_editor_parecer } = immobile_form.$refs
      const { data_form_external } = immobile_form.$refs
      const { data_essential_information } = data_form_external.$refs
      try {
        this.form.immobile.seem = seem_editor_parecer.content
        this.form.immobile.essentialInformation = data_essential_information.content
        this.form.immobile.valorizationPercentContract = parseFloat(this.form.immobile.valorizationPercentContract)
        // eslint-disable-next-line radix
        this.form.immobile.monthsDeliveryContract = parseInt(this.form.immobile.monthsDeliveryContract)
        // eslint-disable-next-line radix
        this.form.immobile.monthsAfterDeliveryContract = parseInt(this.form.immobile.monthsAfterDeliveryContract)
        // eslint-disable-next-line radix
        this.form.immobile.quotaCount = parseInt(this.form.immobile.quotaCount)
        this.form.immobile.valueReceived = parseFloat(this.form.immobile.valueReceived)

        await this.$http.post('/api/services/app/Immobiles/Deliberation', this.form.immobile)
        console.log('[0] {0}', this.form.immobile)
        //debugger
        if (data_form_external.dataFormChild.attachments) {
          // eslint-disable-next-line no-array-constructor
          const photos = new Array()
          data_form_external.dataFormChild.attachments.map(p => photos.push(p.immobilePhotoId))
          await ImmobilePhotosService.alterOrder(photos)
        }

        this.msgSuccess('Imóvel deliberado com sucesso')
        this.loading = false
      } catch (e) {
        if (!this.msgIsOpen()) this.msgError('Houve um erro ao salvar dados do imóvel')
        this.loading = false
      }
      const doc = data_form_external.documentosContratuais
      //  eslint-disable-next-line no-restricted-syntax
      for await (const file of doc) {
        const dto = { id: file.immobileDocumentId, signatories: file.signatories }
        if(file.document) {
          dto = file.document[0]
        }
        dto.signatories = file.signatories
        await this.saveImmobileDocuments(dto)
      }
    },

    // eslint-disable-next-line consistent-return
    async saveImmobileDocuments(data) {
      try {
        data.immobileId = this.form.immobile.id
        await ImmobileDocumentsService.createOrEdit(data)
          .then()
          .catch(error => {
            this.msgError(error.response.data.error.message)
          })
      } catch (error) {
        this.msgError(error.response.data.error.message)
      }
    },

    async registerImmobilePhotos(data) {
      // console.log(data)
      const response = await ImmobilePhotosService.createOrEdit(data)
      if (response.data.error) {
        // this.error = response.data.error.message
        // this.msgError()
      }
    },

    formatPriceBR(i) {
      let v = i.target.value.replace(/\D/g, '')
      v = `${(v / 100).toFixed(2)}`
      v = v.replace('.', ',')
      v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,')
      v = v.replace(/(\d)(\d{3}),/g, '$1.$2,')
      this.formData.expectedRemuneration = v
      return v
    },
  },
}
</script>

<style></style>
